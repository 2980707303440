import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { formatDistance } from 'date-fns'
import AvatarInitials from './AvatarInitials';
import TextAreaWithLimit from './TextAreaWithLimit';
import TextDisplay from './TextDisplay';
import MUILink from '@mui/material/Link';
import LoaderIcon from '../components/LoaderIcon';

const CommentWidget = (props) => {
   const initialState = { comment: '' };
   const [formValues, setFormValues] = useState(initialState);
   const [isCommenting, setIsCommenting] = useState(false);

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.comment.length < 4)
         errors.comment = 'Comment must be at least four characters.';

      setFormValidation(errors);

      return Object.keys(errors).length === 0;
   };

   const handleSaveCommentButtonClick = async () => {
      if (formIsValid()) {
         setIsCommenting(false);
         props.onAddComment(formValues.comment);
         setFormValues({ ...initialState });
      }
   };

   const handleCancelCommentButtonClick = () => {
      setIsCommenting(false);
      setFormValues({ ...initialState });
   };

   const handleFocusCommenting = () => {
      setIsCommenting(true);
   };

   const formatCommentTime = (comment) => {
      var now = new Date();
      var date = new Date(comment.createdAt);
      var created = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() - date.getTimezoneOffset(), date.getSeconds());
      return formatDistance(created, now, {addSuffix: true});
   }

   if(props.loading)
      return (<LoaderIcon />)

   return (
      <Fragment>
         {props.allowAddingComments ? <Fragment>
            <FormControl fullWidth>
               <Grid container wrap="nowrap">
                  <Grid item xs={12}>
                     <TextAreaWithLimit
                        id="comment-input"
                        label="Comment"
                        maxLength={255}
                        value={formValues.comment}
                        onChange={event => setFormValues({ ...formValues, comment: event.target.value })}
                        error={formValidation.comment ? true : false}
                        helperText={formValidation.comment}
                        placeholder="Add a comment..."
                        rows={3}
                        onFocus={() => handleFocusCommenting()}
                     />
                  </Grid>
               </Grid>
            </FormControl>
            <Grid container direction="row" justifyContent="flex-end">
               <Box mx={1}>
                  {isCommenting && <Button variant="outlined" onClick={handleCancelCommentButtonClick}>Cancel</Button>}
               </Box>
               <Box ml={1}>
                  <Button variant="contained" onClick={handleSaveCommentButtonClick} disabled={!isCommenting}>Add Comment</Button>
               </Box>
            </Grid>
         </Fragment> : <Alert sx={{ mb: 1 }} severity="info">Upgrade to premium to add comments.</Alert>}

         {props.comments.length === 0 ?
            <Alert sx={{ mt: 1 }} severity="info">No comments have been left at this mausoleum.</Alert> : props.comments.map(((comment, commentIndex)=> (
            <Grid container wrap="nowrap" key={comment.id} columnSpacing={2}>
               <Grid item>
                  <AvatarInitials basicProfile={comment.createdBy} />
               </Grid>
               <Grid item xs zeroMinWidth>
                  <span>
                     <b style={{ margin: 0, textAlign: 'left' }}>{comment.createdBy.firstName + ' ' + comment.createdBy.lastName}</b>&nbsp;
                     <i style={{ textAlign: 'left', color: 'gray' }}>{formatCommentTime(comment)}</i>
                  </span>
                  <p style={{ marginTop: 0 }}>
                     <TextDisplay
                        text={comment.text}
                        maxChars={120}
                     />
                  </p>
                  {props.allowAddingComments ? <MUILink color="secondary" onClick={() => props.onReportComment(comment.id)} sx={{ cursor: 'pointer', fontSize: 11 }}>Report</MUILink> : null}
                  {commentIndex === props.comments.length - 1 ? '' : <Divider variant="fullWidth" sx={{ margin: '30px 0' }} />}
               </Grid>
            </Grid>
         )))}
      </Fragment>
   );
};

CommentWidget.propTypes = {
   comments: PropTypes.array.isRequired,
   onAddComment: PropTypes.func.isRequired,
   onReportComment: PropTypes.func.isRequired,
   loading: PropTypes.bool,
   allowAddingComments: PropTypes.bool,
};

export default CommentWidget;
