import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Alert from '@mui/material/Alert';
import MUILink from '@mui/material/Link';
import YesNoDialog from './YesNoDialog';

const SubscriptionPicker = (props) => {
   const handleSubscriptionClick = (subscriptionTierId) => {
      if(props.subscriptionStatus.id === subscriptionTierId) {
         // No change, continue with current subscription
         props.onContinueWithoutChange();
      } else if(subscriptionTierId === 'free') {
         // Cancel Subscription
         setSubscriptionChangeDialogState({
            title: 'Cancel Subscription?',
            description: 'Are you sure you want to cancel your LifeTomb subscription?',
            open: true,
            affirmAction: props.onCancelSubscription,
         });
      } else if(props.subscriptionStatus.id === 'free') {
         //Start New Subscription
         props.onNewSubscription(subscriptionTierId);
      } else if(subscriptionTierId !== 'free') {
         //Change Existing Subscription
         setSubscriptionChangeDialogState({
            title: 'Change Subscription?',
            description: 'Are you sure you want to change your LifeTomb subscription?',
            open: true,
            affirmAction: () => props.onChangeSubscription(subscriptionTierId),
         });
      }
   };

   const getSubscriptionText = (newSubscriptionTier, currentSubscriptionTier) => {
      if(newSubscriptionTier.id === 'free' && currentSubscriptionTier.id !== 'free')
         return 'Cancel Subscription';
      else if(newSubscriptionTier.id !== 'free' && newSubscriptionTier.id !== currentSubscriptionTier.id)
         return `Change to ${newSubscriptionTier.name}`;
      else
         return newSubscriptionTier.name;
   };

   const initialSubscriptionChangeDialogState = {
      title: '',
      description: '',
      open: false,
      affirmAction: null,
   };

   const [subscriptionChangeDialogState, setSubscriptionChangeDialogState] = useState(initialSubscriptionChangeDialogState);

   return (
      <Fragment>
         <Grid item xs={12}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
               {props.subscriptionTiers.map((subscriptionTier) => {
                  const labelId = `subscription-list-label-${subscriptionTier.id}`;

                  return (
                     <ListItem key={subscriptionTier.id}>
                        <ListItemButton
                           role="button"
                           sx={{ border: '3px solid', borderColor: subscriptionTier.id === props.subscriptionStatus.id ? '#00a5de' : 'gray', borderRadius: 3 }}
                           onClick={() => handleSubscriptionClick(subscriptionTier.id)}
                           disabled={props.subscriptionStatus.isLocked}
                        >
                           <ListItemIcon>
                              {subscriptionTier.id === props.subscriptionStatus.id ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
                           </ListItemIcon>
                           <ListItemText
                              id={labelId}
                              primary={getSubscriptionText(subscriptionTier, props.subscriptionStatus)}
                              secondary={subscriptionTier.description}
                           />
                        </ListItemButton>
                     </ListItem>
                  );
               })}
            </List>
         </Grid>
         <Grid item xs={12}>
            {props.isStripeCustomer ? <Alert severity="info">Visit the <MUILink onClick={props.onBillingPortalClick} sx={{cursor: 'pointer'}}>billing portal</MUILink> to manage payment methods and other settings.</Alert> : null}
         </Grid>
         <YesNoDialog
            open={subscriptionChangeDialogState.open}
            title={subscriptionChangeDialogState.title}
            description={subscriptionChangeDialogState.description}
            dismissButtonLabel="Cancel"
            affirmButtonLabel="Continue"
            handleDismiss={() => setSubscriptionChangeDialogState(initialSubscriptionChangeDialogState)}
            handleAffirm={() => subscriptionChangeDialogState.affirmAction()}
         />
      </Fragment>
   );
};

SubscriptionPicker.propTypes = {
   subscriptionStatus: PropTypes.object.isRequired,
   subscriptionTiers: PropTypes.array.isRequired,
   isStripeCustomer: PropTypes.bool.isRequired,   
   onNewSubscription: PropTypes.func.isRequired,
   onChangeSubscription: PropTypes.func.isRequired,
   onCancelSubscription: PropTypes.func.isRequired,
   onContinueWithoutChange: PropTypes.func.isRequired,
   onBillingPortalClick: PropTypes.func.isRequired,
};

export default SubscriptionPicker;
