import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import LoaderIcon from './LoaderIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Box, Card, CardActions, CardContent } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

const NotificationList = (props) => {
   const renderNotificationItem = (notification, onAcceptInvite, onRefuteDeceasedReport, onDismiss, onVisitLink) => {
      // todo: clean this up so that it's less copy and paste... really just the buttons and icons are different - probably should do the case in the .map method, then have a "container" component that takes the icon the card action buttons
      switch (notification.type) {
         case 'trustee-invite':
         case 'family-profile-invite':
            return (
               <Card sx={{ m: 1 }}>
                  <CardContent>
                     <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                           <Avatar sx={{ background: '#00a5de' }}>
                              <AdminPanelSettingsIcon />
                           </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                           primary={notification.title}
                           secondary={
                              <Typography
                                 sx={{ display: 'inline' }}
                                 component="span"
                                 variant="body2"
                                 color="text.primary"
                              >
                                 {notification.text}
                              </Typography>
                           }
                        />
                     </ListItem>
                  </CardContent>
                  <CardActions>
                     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button size="small" onClick={() => onDismiss(notification)}>Reject</Button>
                        <Button size="small" variant='contained' onClick={() => onAcceptInvite(notification)}>Accept</Button>
                     </Box>
                  </CardActions>
               </Card>
            );
         case 'deceased-report':
            return (
               <Card sx={{ m: 1 }}>
                  <CardContent>
                     <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                           <Avatar sx={{ background: '#00a5de' }}>
                              <NotificationsIcon />
                           </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                           primary={notification.title}
                           secondary={
                              <Typography
                                 sx={{ display: 'inline' }}
                                 component="span"
                                 variant="body2"
                                 color="text.primary"
                              >
                                 {notification.text}
                              </Typography>
                           }
                        />
                     </ListItem>
                  </CardContent>
                  <CardActions>
                     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button size="small" onClick={() => onRefuteDeceasedReport(notification)}>Cancel</Button>
                     </Box>
                  </CardActions>
               </Card>
            );
         case 'link':
            return (
               <Card sx={{ m: 1 }} variant='outlined'>
                  <CardContent>
                     <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                           <Avatar sx={{ background: '#00a5de' }}>
                              <LaunchIcon />
                           </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                           primary={notification.title}
                           secondary={
                              <Typography
                                 sx={{ display: 'inline' }}
                                 component="span"
                                 variant="body2"
                                 color="text.primary"
                              >
                                 {notification.text}
                              </Typography>
                           }
                        />
                     </ListItem>
                  </CardContent>
                  <CardActions>
                     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button size="small" onClick={() => onDismiss(notification)}>Dismiss</Button>
                        <Button size="small" variant='contained' onClick={() => onVisitLink(notification)}>Go</Button>
                     </Box>
                  </CardActions>
               </Card>
            );
         default:
            return (
               <Card sx={{ m: 1 }} variant='outlined'>
                  <CardContent>
                     <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                           <Avatar sx={{ background: '#00a5de' }}>
                              <NotificationsIcon />
                           </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                           primary={notification.title}
                           secondary={
                              <Typography
                                 sx={{ display: 'inline' }}
                                 component="span"
                                 variant="body2"
                                 color="text.primary"
                              >
                                 {notification.text}
                              </Typography>
                           }
                        />
                     </ListItem>
                  </CardContent>
                  <CardActions>
                     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button size="small" onClick={() => onDismiss(notification)}>Dismiss</Button>
                     </Box>
                  </CardActions>
               </Card>
            );
      }
   };

   return (
      <Fragment>
         {props.loading ? <LoaderIcon /> : null}

         {!props.notifications.length ? <Alert severity="info">No notifications.</Alert> : null}

         {props.notifications.map((notification) => (<Fragment key={notification.id || notification.referenceId}>
            {renderNotificationItem(notification, props.onAcceptInvite, props.onRefuteDeceasedReport, props.onDismiss, props.onVisitLink)}
         </Fragment>))}
      </Fragment>
   );
};

NotificationList.propTypes = {
   loading: PropTypes.bool.isRequired,
   notifications: PropTypes.array.isRequired,
   onAcceptInvite: PropTypes.func.isRequired,
   onRefuteDeceasedReport: PropTypes.func.isRequired,
   onDismiss: PropTypes.func.isRequired,
   onVisitLink: PropTypes.func, //todo: show the "go" button if this is a function
};

export default NotificationList;
